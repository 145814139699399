<template>
    
    <div class="h-auto w-full">

        <div class="h-auto w-full mb-4 rounded-xl flex flex-col overflow-hidden shadow_module cursor-pointer" :class="'bg-module-'+mode"  @click="tocomerciallist(data[0], data[1].Name)">

            <div class="h-12 flex flex-row justify-start items-center px-4" :class="'bg-box-'+mode">
            
                <span class="text-lg font-bold text-red">{{ data[1].Name }}</span>

                <i class="mdi mdi-arrow-right text-2xl text-red ml-auto"></i>
                
            </div>

            <div class="h-auto w-full px-2 py-4">

                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('accumulatedBilling')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].Sales | reduceBigNumbers()}} €</span>
                </div>
                
                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('accumulatedSquareMeters')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].Meters | reduceBigNumbers()}} m<sup>2</sup></span>
                </div>
                
                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('averagePriceLowerCase')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].MediumPrice | reduceBigNumbers(2)}} €/m<sup>2</sup></span>
                </div>
                
                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('margin')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].Margin | reduceBigNumbers(2)}} %</span>
                </div>
                
                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('deliveryNotesDay')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].DeliveryNote | reduceBigNumbers(2)}} €</span>
                </div>
                
                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('totalOperational')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].OperativeOrdersToday | reduceBigNumbers(2)}} €</span>
                </div>
                
                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('nearbyOperational')}} {{$t('day')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].OperativeOrdersNextDay | reduceBigNumbers(2)}} €</span>
                </div>
                
                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('totalsOC')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].PreparedOrder | reduceBigNumbers(2)}} €</span>
                </div>
                
                <div class="h-auto w-full flex flex-row justify-start items-center px-2 py-1">
                    <span class="text-xs mr-auto" :class="'text-font_gray-'+mode">{{$t('orderBookCurrent')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data[1].PendingOrder | reduceBigNumbers(2)}} €</span>
                </div>

            </div>

            <div v-if="permisions" class="h-auto">
                
                <Request model="Grouptocompanymessage" action="create" :form="form" @success="onSuccess" @error="onError" v-slot="{ request , loading:loadingrequest }" class="w-full">
                    
                    <div v-if="!loadingrequest" class="h-auto">

                        <div v-if="user.email === 'admin@mail.zeus.vision' && rol === 'Propiedad'" class="h-10 mb-2 flex flex-row justify-start items-center px-4">
                            <div class="h-8 flex-1 pr-2" @keypress.enter="request">
                                <input ref="input" type="text" v-model="message" :placeholder="$t('writeMessage')"
                                    class="w-full h-full rounded-lg px-2 text-xs" :class="'bg-box-'+mode + ' text-dfont-'+mode">
                            </div>
                            <div @click="request" class="h-8 w-10 rounded-lg flex flex-row justify-center items-center bg-opacity-50" :class="'bg-font_gray-'+mode">
                                <i class="mdi mdi-send text-white text-xl"></i>
                            </div>
                        </div>
                        
                    </div>

                </Request>

            </div>

        </div>

    </div>

</template>

<script>
import { state } from '@/store';

export default {
    props:['data'],
    computed:{
        mode(){
            return state.mode
        },
    },
    methods:{
        tocomerciallist(type, zone){
            this.$emit('tocomerciallist', type, zone)
        }
    }
}

</script>

<style scoped>

</style>