
<template>

    <div class="w-full p-1" :class="{'h-52': mobile, 'h-full': !mobile}">

        <div class="h-full w-full rounded-lg flex flex-col shadow_box" :class="'bg-box-'+mode">

            <div class="h-auto flex flex-row justify-start items-center p-2">
                <span class="font-medium text-sm" :class="'text-dfont-'+mode">{{ $t('loadingOrders') }}</span>
                <span class="text-2xs ml-auto capitalize" :class="'text-font_gray-'+mode">{{ $t('today') }}</span>
            </div>

            <div class="flex flex-1 flex-col justify-center items-center leading-none">

                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('totalOperational')}}</span>
                <span class="text-sm font-medium mt-1" :class="'text-dfont-'+mode">{{ data.OperativeOrdersToday | reduceBigNumbers() }} €</span>

                <span class="text-xs mt-3" :class="'text-font_gray-'+mode">{{$t('nearbyOperational')}} {{ $t('day') }}</span>
                <span class="text-sm font-medium mt-1" :class="'text-dfont-'+mode">{{ data.OperativeOrdersNextDay | reduceBigNumbers() }} €</span>

                <span class="text-xs font-medium mt-3" :class="'text-font_gray-'+mode">{{$t('totalsOC')}}</span>
                <span class="text-2xs" :class="'text-font_gray-'+mode">{{$t('operationalAndPending')}}</span>
                <span class="text-sm font-medium mt-1" :class="'text-dfont-'+mode">{{ data.OperativeOrdersTodayTomorrow | reduceBigNumbers() }} €</span>

            </div>

            <div class="mt-auto text-2xs text-center mb-1">
                <span :class="'text-dfont-'+mode">{{$t('lastSynchronization')}} <strong :class="'text-dfont-'+mode">{{ data.SyncTime | moment('HH:mm') }}</strong></span>
            </div>

        </div>

    </div>

</template>

<script>
    import solidgauge from '@/components/solidgauge.vue';
    import { state } from '@/store';

    export default {
        props: ['data'],
        components: {
            solidgauge
        },
        data() {
            return {
                datasolidgauge: {
                    actual: 0,
                    prev: 0
                }
            }
        },
        computed:{
            mode(){
                return state.mode
            },
            mobile(){
                return state.isMobile
            }
        }
    }
</script>
