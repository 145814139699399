
<template>

    <div class="w-full p-1" :class="{'h-48': mobile, 'h-full': !mobile}">

        <div class="h-full shadow_box w-full rounded-xl flex flex-col " :class="'bg-box-'+mode">

            <div class="h-auto flex flex-row justify-start items-center p-2">
                <span class="font-medium text-sm" :class="'text-dfont-'+mode">{{ $t('activeClients') }}</span>
                <span v-if="lastSale" class="text-2xs ml-auto" :class="'text-font_gray-'+mode">{{ lastSale | moment("DD MMM") }}</span>
            </div>

            <div class="flex flex-1 min-h-0 flex-col justify-center items-center px-4">

                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('activeClients')}}</span>
                <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.YearActive-data.New | numberFormat(0)}}</span>

                <span class="text-xs mt-2" :class="'text-font_gray-'+mode">{{$t('new')}} {{year}}</span>
                <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ data.New | numberFormat(0)}}</span>

                <div class="h-1 w-full border-t mt-2" :class="'border-dfont-'+mode"></div>

                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('total')}} {{$t('last12Months')}}</span>
                <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ data.YearActive | numberFormat(0)}}</span>

            </div>            

        </div>

    </div>

</template>

<script>
    import { state } from '@/store';

    export default {
        props:['data'],
        data(){
            return{
                year: this.$moment().format('YYYY')
            }
        },
        computed:{
            mode(){
                return state.mode
            }
        }
    }
</script>
