<template>

    <div :class="{'w-full h-full px-3 py-4': mobile, 'h-full p-2 overflow-hidden': !mobile}">

        <All model="Companyinfo" :immediate="true" :config="config" :query="query" v-slot="{data:infodata, loading: loadingdata}">

            <All v-if="!loadingdata" model="Company" :immediate="true" :config="config" :query="query" v-slot="{data,loading}">

                <div v-if="!loading" class="" :class="{'h-full grid grid-cols-3 gap-3 overflow-hidden': !mobile}">

                    <div :class="{'col-span-2 overflow-auto': !mobile}">

                        <div class="h-auto w-full rounded-lg shadow_module flex flex-col p-2 mb-4" :class="'bg-module-'+mode">

                            <div v-if="permisions" class="absolute bottom-0 right-0 h-auto flex w-auto z-30 m-2" >

                                <div class="h-10 w-10 rounded-full bg-red flex flex-row justify-center items-center" @click="toTops()">
                                    <i class="mdi mdi-filter-variant text-white text-xl font-semibold"></i>
                                </div>

                            </div>

                            <readmessages v-if="permisions" endpoint="Companymessage" :querymessage="querymessage" :class="'bg-box-'+mode"/>

                            <div v-if="!preciomedio && !margen" class="h-auto w-full mt-2" :class="{'grid grid-cols-2 gap-2': !facturation && !metroscuadrados}">

                                <billing v-if="!metroscuadrados" :data="data.BubbleSale" model="Companybubbledetails"
                                    :config="configFacturation" @openFacturation="openFacturation" :lastSale="infodata.LastSaleDate" />

                                <meters v-if="!facturation" :data="data.BubbleMeter" model="Companybubbledetails" :config="configMetros"
                                    @openMeters="openMeters" :lastSale="infodata.LastSaleDate" />

                            </div>

                            <div v-if="!facturation && !metroscuadrados" class="h-auto w-full mt-2" :class="{'grid grid-cols-2 gap-2': !margen && !preciomedio}">

                                <halfprice v-if="!margen" :data="data.BubbleMediumPrice" model="Companybubbledetails"
                                    :config="configPreciomedio" @fromhalfprice="openHalfPrice" :isOpen="preciomedio"
                                    :lastSale="infodata.LastSaleDate" />

                                <margin v-if="!preciomedio" :data="data.BubbleMargin" model="Companybubbledetails" :config="configMargen"
                                    @openMargen="openMargen" :isOpen="margen" :lastSale="infodata.LastSaleDate" />

                            </div>

                            <All v-if="!preciomedio && !margen && !facturation && !metroscuadrados"
                            model="Companydetails"
                            :immediate="true"
                            :config="config"
                            :query="query"
                            v-slot="{data: datadetail, loading: loadingdetail}">

                                <div v-if="!loadingdetail" class="h-auto grid grid-cols-2 gap-2 mt-2 mb-2">

                                    <div class="w-full h-full">
                                        <clients :data="datadetail.BubbleClient"/>
                                    </div>

                                    <div class="w-full h-full">
                                        <orders :data="datadetail.BubblePendingOrder" :lastSale="infodata.LastSaleDate"/>
                                    </div>

                                    <div class="w-full h-full">
                                        <deliverynotes :data="datadetail.BubbleDeliveryNoteOrders"/>
                                    </div>

                                    <div class="w-full h-full">
                                        <loadorders :data="datadetail.BubbleOrders"/>
                                    </div>

                                </div>

                            </All>

                        </div>

                    </div>

                    <div :class="{'h-full overflow-auto': !mobile, 'h-auto': mobile}">

                         
                        <All model="Comercialsbytype" :immediate="true" :config="config" :query="query" v-slot="{data:comercials,loading:loading2}">
                            
                            <div v-if="!loading2" class="h-auto">

                                <comercial-type-box v-for="(el, index) in Object.entries(comercials)" :key="index" :data="el" @tocomerciallist="tocomerciallist"/>

                            </div>

                        </All>

                    </div>

                    <div v-if="mobile" class="h-6"></div>

                </div>

                <div v-else class="h-20 relative">
                    <loader :loading="loading" />
                </div>

            </All>

        </All>
        
    </div>

</template>

<script>
    import { state, actions } from '@/store';
    import solidgauge from '@/components/solidgauge.vue';
    import { All } from '@/api/components';
    import loader from '@/components/loader.vue';
    import billing from '@/components/billing.vue';
    import meters from '@/components/meters.vue';
    import halfprice from '@/components/halfprice.vue';
    import margin from '@/components/margin.vue';
    import orders from '@/components/orders.vue';
    import clients from '@/components/clients.vue';
    import loadorders from '@/components/loadorders.vue';
    import deliverynotes from '@/components/deliverynotes.vue';
    import readmessages from '@/components/readmessages.vue';
import ComercialTypeBox from '../../components/comercialTypeBox.vue';

    export default {
        name: 'representante',
        components: {
            solidgauge,
            All,
            loader,
            billing,
            meters,
            halfprice,
            margin,
            orders,
            clients,
            loadorders,
            deliverynotes,
            readmessages,
                ComercialTypeBox
        },
        data() {
            return {
                facturation: false,
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
                config: {
                    data: { Company: null }
                },
                idCompany: null,
                yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
                month: this.$moment().format("MMM 'YY"),
                montprevyear: this.$moment().subtract(1, 'years').format("MMM 'YY"),
                montprev: this.$moment().subtract(1, 'months').format("MMM 'YY"),
                preciomedio: false,
                margen: false,
                metroscuadrados: false,
                facturation: false,
                messageopen: false
            }
        },
        methods: {
            translate(word) { return this.$t(word) },
            toTops() { this.$router.push({ name: 'companytops' }) },
            activeFacturation() { this.facturation = !this.facturation },
            tocomerciallist(type, zone) {
                if (state.user.rol.Name === 'Propiedad' || state.user.rol.Name === 'Gerente') {
                    actions.setZoneSelected(zone);
                    actions.setSaleTypeSelected(type);
                    
                    if(zone === 'Nacional'){
                        actions.setTopDimension('Province')
                        actions.setTopDimensionName('Provincias de España')
                    } else {
                        actions.setTopDimension('Country')
                        actions.setTopDimensionName('Paises')
                    }

                    this.$router.push({name:'zones', params:{saletype:type}})
                } else if (state.user.rol.Name === 'Comercial') {
                    for (let index = 0; index < state.user.relation.length; index++) {
                        if (type.toLowerCase() === state.user.relation[index].IdSaleType.toLowerCase()) {
                            actions.setZoneSelected(zone);
                            actions.setSaleTypeSelected(type);
                            this.$router.push({name:'zones', params:{saletype:type}})
                        }
                    }

                }
            },
            toFilters() { this.$router.push({name : 'companyfilters'}) },
            openFacturation() {
                this.facturation = !this.facturation
                this.metroscuadrados = false
            },
            openMeters() {
                this.metroscuadrados = !this.metroscuadrados
                this.facturation = false
            },
            openPreciomedio() {
                this.preciomedio = !this.preciomedio
                this.margen = false
            },
            openMargen() { this.margen = !this.margen },
            openHalfPrice() { this.preciomedio = !this.preciomedio },
        },
        computed: {
            mobile(){
                return state.isMobile
            },
            monthName(){
                return this.$moment({M: state.month -1 }).format('MMMM')
            },
            user() { return state.user },
            nameCompany() { return state.companySelected },
            typecomercialselected() { return state.typeComercialsSelected },
            rol() { return state.user.rol.Name },
            configFacturation() {
                return {
                    data: {
                        Dimension: 0,
                        Company: state.idCompany,
                        IndustrialGroup: this.industrialGroup,
                        period: state.period
                    },
                };
            },
            configMetros() {
                return {
                    data: {
                        Dimension: 1,
                        Company: state.idCompany,
                        IndustrialGroup: this.industrialGroup,
                        period: state.period
                    },
                };
            },
            configPreciomedio() {
                return {
                    data: {
                        Dimension: 2,
                        Company: state.idCompany,
                        IndustrialGroup: this.industrialGroup,
                        period: state.period
                    },
                };
            },
            configMargen() {
                return {
                    data: {
                        Dimension: 3,
                        Company: state.idCompany,
                        IndustrialGroup: this.industrialGroup,
                        period: state.period
                    },
                };
            },
            query() {
                return {
                    period: state.period,
                    IndustrialGroup: this.industrialGroup,
                    month: state.month
                }
            },
            periodo() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().format("MMM 'YY")
                        break;
                    case 'quarter':
                        return 'Trim.' + this.$moment().format("Q 'YY")
                        break;
                    case 'year':
                        return this.$moment().format("YYYY")
                        break;
                }
            },
            industrialGroup() { return state.industrialGroup },
            periodo2() {
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            querymessage() { return { Company: state.idCompany } },
            permisions() {
                if (this.rol == 'Propiedad'){ return true }
                else
                    if (state.user.rol.Name == 'Gerente') { return true }
                    else { return false }
            },
            mode(){
                return state.mode
            }
        },
        mounted() {
            if (this.$route.params.company) { this.config.data.Company = this.$route.params.company }
            else { this.config.data.Company = state.idCompany }
            this.idCompany = this.config.data.Company;
            if (state.idCompany === '1BF7128E394211EA966B005056AEAA71' && state.industrialGroup === null) { actions.setIndustrialGroup(true) }
        }
    }
</script>
